import { ErrorHandler, Injectable, inject } from '@angular/core';

import { ENV } from '@trtl/env';

@Injectable()
export class ElasticHandler implements ErrorHandler {

  private readonly env = inject(ENV);

  private readonly worker = new Worker(new URL('./elastic-worker', import.meta.url));

  constructor() {
    this.worker.postMessage(JSON.stringify({
      type: 'init',
      serverUrl: this.env.elastic.serverUrl,
      environment: this.env.environment,
    }));
  }

  handleError(error: unknown): void {
    this.worker.postMessage(JSON.stringify({
      type: 'error',
      error,
    }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
