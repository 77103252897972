import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class I18nService implements TranslocoLoader {

  private readonly http = inject(HttpClient);

  public getTranslation(lang: string) {
    return this.http.get<Translation>(`./assets/i18n/${lang ?? 'en'}.json`);
  }
}
