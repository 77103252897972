import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  Routes,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import { provideTransloco } from '@ngneat/transloco';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';

import { AuthInterceptor, AuthState } from '@trtl/auth';
import { ENV } from '@trtl/env';
import { I18nService } from '@trtl/i18n';
import { ScreenState } from '@trtl/state/screen';
import { PortalUserState, Role } from '@trtl/state/user';

import { ElasticHandler } from '../elastic';
import { environment } from '../environments/environment';

import {
  TrtlErrorStateMatcher,
  authGuard,
  userGuard,
} from './_shared';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full',
      },
      {
        path: 'auth',
        loadChildren: () => import('./auth'),
      },
      {
        path: 'trtlAdmin',
        redirectTo: 'doctor',
        pathMatch: 'full',
      },
      {
        path: 'groupAdmin',
        redirectTo: 'doctor',
        pathMatch: 'full',
      },
      {
        path: 'doctor',
        canActivate: [
          authGuard,
          userGuard,
        ],
        data: {
          roles: [
            Role.Doctor,
            Role.GroupAdmin,
            Role.TrtlAdmin,
          ],
        },
        loadChildren: () => import('./doctor'),
      },
      {
        path: 'patient',
        canActivate: [
          authGuard,
          userGuard,
        ],
        data: {
          roles: [
            Role.Patient,
          ],
        },
        loadChildren: () => import('./patient'),
      },
      {
        path: 'upload-ocr',
        loadChildren: () => import('@trtl/ocr'),
      },
    ],
    providers: [
      importProvidersFrom(
        NgxsModule.forFeature([
          AuthState,
          PortalUserState,
        ]),
      ),
    ],
  },
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      }),
    ),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    provideTransloco({
      config: {
        availableLangs: ['en', 'ar'],
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
        failedRetries: 3,
      },
      loader: I18nService,
    }),

    importProvidersFrom(
      NgxsModule.forRoot(
        [
          ScreenState,
        ],
        {
          selectorOptions: {
            injectContainerState: false,
            suppressErrors: false,
          },
        },
      ),
      NgxsReduxDevtoolsPluginModule.forRoot(),
      NgxsSelectSnapshotModule.forRoot(),
    ),

    {
      provide: ENV,
      useFactory: () => environment,
    },
    {
      provide: ErrorStateMatcher,
      useClass: TrtlErrorStateMatcher,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AuthInterceptor,
    },
    {
      provide: ErrorHandler,
      useClass: ElasticHandler,
    },
  ],
};
