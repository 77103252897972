import { EnvironmentConfig } from '@trtl/env';

export const environment: EnvironmentConfig = {
  aws: {
    region: 'me-south-1',
    userPoolId: 'me-south-1_qaIi43KwY',
    userPoolWebClientId: '788tna08ai6toluo62m16llp9t',
  },
  api: 'https://prod-api.trtl.health',
  elastic: {
    serverUrl: 'https://2a7a374dce624574aefbf6440a2db514.apm.eu-central-1.aws.cloud.es.io:443',
  },
  environment: 'production',
  trtl: 'https://trtl.health',
};
